import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-options", "id", "data-bg-src"]
const _hoisted_2 = ["id", "data-src", "data-options"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.dataBgSrc)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "Sirv",
        "data-options": _ctx.stringOptions,
        id: _ctx.id,
        "data-bg-src": _ctx.dataBgSrc
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("img", {
        key: 1,
        class: "Sirv",
        id: _ctx.id,
        "data-src": _ctx.dataSrc,
        "data-options": _ctx.stringOptions
      }, null, 8, _hoisted_2))
}