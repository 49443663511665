import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-options", "id", "data-src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sirv_component = _resolveComponent("sirv-component")!

  return (_openBlock(), _createElementBlock("div", {
    class: "Sirv",
    "data-options": _ctx.stringOptions,
    id: _ctx.id,
    "data-src": _ctx.dataSrc
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parsedComponents, (slide) => {
      return (_openBlock(), _createBlock(_component_sirv_component, {
        key: slide.src,
        src: slide.src,
        type: slide.type,
        componentOptions: slide.dataOptions,
        id: slide.id,
        thumbnailImage: slide.dataThumbnailImage,
        thumbnailHtml: slide.dataThumbnailHtml,
        slideDisabled: slide.dataDisabled,
        swipeDisabled: slide.dataSwipeDisabled,
        hiddenSelector: slide.dataHiddenSelector,
        pinned: slide.dataPinned,
        staticImage: slide.staticImage
      }, null, 8, ["src", "type", "componentOptions", "id", "thumbnailImage", "thumbnailHtml", "slideDisabled", "swipeDisabled", "hiddenSelector", "pinned", "staticImage"]))
    }), 128)),
    (_ctx.parsedComponents.length == 0)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}