import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-src", "data-options", "id", "data-thumbnail-image", "data-thumbnail-html", "data-disabled", "data-swipe-disabled", "data-hidden-selector"]
const _hoisted_2 = ["innerHTML", "data-type", "data-src", "data-options", "id", "data-thumbnail-image", "data-thumbnail-html", "data-disabled", "data-swipe-disabled", "data-hidden-selector"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.type == 'image')
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        "data-src": _ctx.src,
        "data-options": _ctx.optionsToString,
        id: _ctx.id,
        "data-thumbnail-image": _ctx.thumbnailImage,
        "data-thumbnail-html": _ctx.thumbnailHtml,
        "data-disabled": _ctx.slideDisabled,
        "data-swipe-disabled": _ctx.swipeDisabled,
        "data-hidden-selector": _ctx.hiddenSelector
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        innerHTML: _ctx.type == 'html' ? _ctx.src : null,
        "data-type": _ctx.type == 'zoom' ? 'zoom' : (_ctx.staticImage || null),
        "data-src": _ctx.type == 'html' ? null : _ctx.src,
        "data-options": _ctx.optionsToString,
        id: _ctx.id,
        "data-thumbnail-image": _ctx.thumbnailImage,
        "data-thumbnail-html": _ctx.thumbnailHtml,
        "data-disabled": _ctx.slideDisabled,
        "data-swipe-disabled": _ctx.swipeDisabled,
        "data-hidden-selector": _ctx.hiddenSelector
      }, null, 8, _hoisted_2))
}